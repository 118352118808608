.select_marketplace {
  margin-top: 0.65rem!important;
}
.select_marketplace img {
  height: 40%!important;
}
  
  
  @media (max-width: 1199.98px) {
    
  }
  
  @media (max-width: 576px) { 

  }